/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700;900&family=Roboto:wght@400;500;700&display=swap");
/* BREAKPOINTS */
html, body {
  background-color: #F5F5F5;
  min-height: 100vh;
}

body:fullscreen {
  background-color: #f5f5f5;
  min-height: 100%;
  overflow: auto;
}

.logo {
  text-align: center;
  text-transform: uppercase;
  color: #464646;
}

.logo .top {
  letter-spacing: 0.2em;
  font-size: 13px;
  margin-bottom: .25rem;
  font-weight: 500;
}

.logo .middle {
  letter-spacing: 0.14em;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: .25rem;
}

.btn-green {
  background-color: #749A57;
}

.btn-blue-grey {
  background-color: #4F799F;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.btn-blue-grey img {
  margin-right: .5rem;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #F5F5F5;
}

.sidebar {
  width: 280px;
  height: 0;
  padding-top: 60px;
  -webkit-box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0 30px 30px 0;
  min-height: 100vh;
  background-color: white;
  position: fixed;
}

.sidebar.hide {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  visibility: hidden;
  width: 0;
}

@media (max-width: 60em) {
  .sidebar {
    display: none;
  }
}

.sidebar-header {
  margin-bottom: 20px;
}

.sidebar-body {
  height: 74%;
  overflow: hidden;    
}

.sidebar-body:hover {
  overflow-y:scroll;
  overflow-x: hidden;
}

.sidebar-body::-webkit-scrollbar {
  width: .5em;
}
 
.sidebar-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebar-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.sidebar-body > ul {
  list-style-type: none;
  padding: 0 26px 1rem 1rem;
  margin: 0;
}

.sidebar-body > ul > a > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: .3rem .8rem;
  border-radius: 3px;
  margin-top: 8px;
}

.sidebar-body .sub-submenu > ul > a > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: .3rem .8rem;
  border-radius: 3px;
  margin-top: 8px;
}

.sidebar-body > ul > a > li img {
  margin-right: .75rem;
}

.sidebar-body > ul > a > li .icon-collapse {
  justify-self: flex-end;
}

.sidebar-body > ul > a > li .icon-collapse img {
  margin: 0;
}

.sidebar-body > ul > a > li.active {
  font-weight: 900;
  background: #536F3E0D;
}

.sidebar-body > ul > a > li.active:before {
  content: '';
  height: 80%;
  position: absolute;
  width: 4px;
  border-radius: 1rem;
  left: -.9rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #75B940;
}

.sidebar-body > ul a.collapse-menu li {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sidebar-body > ul a[aria-expanded="true"] li {
  font-weight: 900;
  background: #536F3E0D;
}

.sidebar-body > ul a[aria-expanded="true"] .icon-collapse {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.sidebar-body .submenu ul {
  list-style-type: none;
  padding-left: calc(27px + .75rem + 1rem);
}

.sidebar-body .submenu ul li {
  padding: 7px 0;
}

.sidebar-body .submenu ul li.active {
  font-weight: bold;
}

.sidebar-body .submenu .sub-submenu ul {
  list-style-type: none;
  padding-left: calc(0px + .25rem + 1rem);
}

.content {
  margin-left: 280px;
  width: calc(100% - 280px);
  padding: 0rem 2rem;
}

/* 
.full{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
} */

.content.expand {
  margin-left: 0;
  width: 100%;
}

@media (max-width: 60em) {
  .content {
    width: 100%;
    margin-left: 0;
    padding: 1rem;
  }
  .go-fs {
    display: none;
  }
}

.contentfull {
  width: 100%;
  padding: 2rem;
  /* margin: 0.5rem; */
  background-color: #F5F5F5;
}

.contentfull.expand {
  width: 100%;
}

@media (max-width: 60em) {
  .contentfull {
    width: 100%;
    padding: 1rem;
  }
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  color: #464646;
  border: 3px solid transparent;
  border-bottom: 0;
}

.nav-tabs .nav-link.active {
  background-color: #749A57;
  color: white;
  border-color: #EBCB7B;
}

.tabs-title {
  background-color: #749A57;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 20px;
  margin-bottom: 20px;
}

.navigation {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding: 2rem 0 .5rem;
  margin-bottom: .5rem;
  background-color: #F5F5F5;
  z-index: 999;
}

.navigation .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 3rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.navigation .header .header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
}

.navigation .header .header-left .sidebar-collapse {
  margin-right: 1rem;
}

.navigation .header .search-bar {
  position: relative;
  width: 40%;
}

.navigation .header .search-bar .form-control {
  padding-left: 2.5rem;
  border-radius: 30px;
  width: 100%;
  border-color: transparent;
}

.navigation .header .search-bar .icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-top: -2px;
}

.navigation .header .dropdown-toggle:after {
  margin-left: 1rem;
}

.navigation .header .user {
  color: #676767;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navigation .header .user img {
  margin-right: .75rem;
  height: 35px;
  width: 35px;
  -o-object-fit: cover;
     object-fit: cover;
}

.navigation .header .user .name {
  white-space: nowrap;
}

.navigation .header .mobile-sidebar {
  display: none;
}

@media (max-width: 60em) {
  .navigation .header .mobile-sidebar {
    display: block;
    margin-bottom: 1rem;
  }
  .navigation .header .sidebar-collapse {
    display: none;
  }
  .navigation .header .user {
    margin-bottom: 1rem;
  }
  .navigation .header .search-bar {
    width: 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}

.breadcrumb {
  color: #939393;
}

.breadcrumb span.current {
  color: #88C05C;
}

.table-wrapper {
  margin-bottom: 4rem;
}

.table-wrapper .table-header {
  background-color: #749A57;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: white;
  border-radius: 3px;
  margin-bottom: .5rem;
}

.table-wrapper .table-header .form-select {
  font-family: 'Lato', sans-serif;
  font-size: 11px;
}

.table-wrapper .table-options {
  margin: .5rem 0;
}

.table-wrapper .table-options .show-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
}

.table-wrapper .table-options .show-table .form-control {
  font-size: 11px;
  margin: 0 .5rem;
  max-width: 60px;
}

.table-wrapper .table {
  font-size: 12px;
  text-align: center;
  background-color: white;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: .5rem;
  vertical-align: middle;
}

.table-wrapper .table-left {
  text-align: left;
}

.table-wrapper .table thead {
  background-color: #305711;
  color: white;
}

.table-wrapper .table thead.bordered th {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.table-wrapper .table thead.bordered th:last-child {
  border-right: none;
}

.table-wrapper .table thead.bordered th:first-child {
  border-left: none;
}

.table-wrapper .table tr.summary {
  background-color: #D5E0CD;
  font-weight: bold;
}

.table-wrapper .table tr.summary td {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.table-wrapper .table tr.summary td:last-child {
  border-right: none;
}

.table-wrapper .table tr.summary td:first-child {
  border-left: none;
}

/* CUATOM TR STYLE */
.table-wrapper .table tr.warning {
  background-color: #f2dede;
  color: #a7454b;
  font-weight: bold;
}

.table-wrapper .table tr.warning td {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.table-wrapper .table tr.warning td:last-child {
  border-right: none;
}

.table-wrapper .table tr.warning td:first-child {
  border-left: none;
}

.table-wrapper .table tr.empty {
  background-color: #F9ECD3;
  color: #815934;
}

.table-wrapper .pagination-wrapper {
  background-color: #D3E2C8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 6px 40px;
  border-radius: 0 0 3px 3px;
}

.table-wrapper .pagination-wrapper .pagination {
  margin-bottom: 0;
}

.table-wrapper .pagination-wrapper .pagination .page-item.disabled .page-link {
  color: #464646;
}

.table-wrapper .pagination-wrapper .pagination .page-item .page-link {
  background-color: transparent;
  color: #567C38;
  font-weight: bold;
  border: none;
}

.table-wrapper .pagination-wrapper .pagination .page-item .page-link:hover {
  cursor: pointer;
}

.table-wrapper .pagination-wrapper .pagination-number .page-item .page-link {
  color: #464646;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 .1rem;
}

.table-wrapper .pagination-wrapper .pagination-number .page-item .page-link:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(48, 87, 17, 0.2);
          box-shadow: 0 0 0 0.25rem rgba(48, 87, 17, 0.2);
}

.table-wrapper .pagination-wrapper .pagination-number .page-item .page-link:hover {
  cursor: pointer;
}

.table-wrapper .pagination-wrapper .pagination-number .page-item.active .page-link {
  background-color: white;
  border: none;
  cursor: default;
}

@media (max-width: 60em) {
  .table-wrapper .pagination-wrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 6px 1rem;
  }
  .table-wrapper .pagination-wrapper .pagination-number {
    margin-bottom: .5rem;
  }
  .table-wrapper .pagination-wrapper > div:first-child {
    display: none;
  }
}

.card .card-header {
  background-color: #305711;
  color: white;
  font-weight: bold;
  padding: 6px 22px;
  font-size: 15px;
}

.card .card-body {
  padding: 20px 22px;
  font-size: 12px;
}

.card .card-body .grey-text {
  color: #676767;
}

.card .card-body p {
  min-height: 16px;
  margin-bottom: .75rem;
}

.card.secondary .card-header {
  background-color: #EB882C;
}

.pendaftaran {
  margin-bottom: 4rem;
}

.pendaftaran .card-header {
  font-size: 12px;
}

.pendaftaran .card-body {
  font-size: 11px;
}

@media (max-width: 60em) {
  .pendaftaran .col-12 {
    margin-bottom: 1rem;
  }
}

.history {
  font-family: 'Roboto';
}

.history .history-desc {
  position: relative;
  border-right: 1px solid grey;
}

.history .history-desc:after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: .8rem;
  right: -.4rem;
  background-color: #9FE26C;
  border: 3px solid #749A57;
}

.history .history-desc .history-date, .history .history-desc .history-time {
  text-align: end;
}

@media (max-width: 60em) {
  .history .history-desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-right: 0;
    border-bottom: 1px solid lightgray;
  }
  .history .history-desc .history-date, .history .history-desc .history-time {
    text-align: start;
  }
  .history .history-desc:after {
    display: none;
  }
}

.history .history-table thead {
  background-color: #D7AA38;
}

.history .history-table thead tr th {
  border: 1px solid white;
}

.history .history-table thead tr th:first-child {
  border-left: 0;
}

.history .history-table thead tr th:last-child {
  border-right: 0;
}

.history .history-table thead .up {
  background-color: #8E7840;
}

.history .history-table .table-wrapper {
  margin-bottom: 1rem;
}

.history .history-table tbody {
  background-color: #8E7840;
  color: white;
}

.history .history-table tbody .text-start {
  border-right: 1px solid white;
}

.history .history-table tbody tr:last-child {
  border-top: 1px solid white;
}

@media (max-width: 60em) {
  .history .history-table {
    margin-bottom: 1.5rem;
  }
}

.back-button {
  cursor: pointer;
  display: flex;  
  width: 29px;
  height: 15px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #464646;
  margin-bottom: 16px;
}

.siswa-baru .back-button img {
  padding-right: 10px;
}

.siswa-baru .item-header {
  font-weight: bold;
  padding: 14px 20px;
  color: white;
  background-color: #749A57;
  text-transform: uppercase;
  border-radius: 3px;
  margin-bottom: .75em;
}

.siswa-baru .card {
  margin-bottom: 1.5rem;
}

.siswa-baru .card .card-header {
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}

.siswa-baru .card .form-control.highlight, .siswa-baru .card .form-select.highlight {
  background-color: #EFA146;
  color: #fcead9;
}

.siswa-baru .card .form-label {
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.siswa-baru .card .required {
  color: red;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  color: #464646;
  font-size: 20px;
  margin-bottom: 1rem;
}

.siswa-baru-list .table {
  overflow: auto;
}

.siswa-baru-list .table thead .up {
  vertical-align: middle;
}

.siswa-baru-list .table thead .below {
  background-color: #5E9831;
}

.siswa-baru-list .charts .table-header {
  font-size: 13px;
}

.siswa-baru-list .list-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 2rem;
}

.siswa-baru-list .list-options form {
  width: 100%;
}

.siswa-baru-list .list-options .form-select-warapper {
  width: 15%;
  margin-right: 1rem;
}

.siswa-baru-list .list-options .form-select {
  width: 100%;
  margin-right: 1rem;
}

.siswa-baru-list .list-options .form-date {
  position: relative;
  width: 15%;
  margin-right: 1rem;
}

.siswa-baru-list .list-options .form-date-inline {
  position: relative;
  width: 20%;
  margin-right: 1rem;
}

.siswa-baru-list .list-options .form-date-inline label {
  float: left;
}

.siswa-baru-list .list-options .form-date-inline span {
  display: block;
  overflow: hidden;
  padding: 0px 4px 0px 6px;
}

.siswa-baru-list .list-options .search {
  position: relative;
  width: 30%;
  margin-right: 1rem;
}

.siswa-baru-list .list-options .search .icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

.siswa-baru-list .list-options .btn {
  margin: 0 .5rem;
}

@media (max-width: 60em) {
  .siswa-baru-list .list-options {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .siswa-baru-list .list-options .form-select-warapper {
    width: 48%;
    margin-bottom: .5rem;
  }
  .siswa-baru-list .list-options .form-select {
    width: 100%;
  }
  .siswa-baru-list .list-options .search {
    width: 47%;
    margin-right: 0;
    margin-bottom: .5rem;
  }
  .siswa-baru-list .list-options .btn {
    margin-bottom: .5rem;
  }
}

.siswa-baru-list .settings .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.siswa-baru-list .settings .dropdown-item:hover {
  cursor: pointer;
}

.siswa-baru-list .settings .dropdown-item img {
  margin-right: .5rem;
}

.siswa-baru-list .tagihan {
  padding: 2px 10px;
  background-color: #19804D;
  color: white;
}

.siswa-baru-list .tagihan.secondary {
  background-color: #CD7626;
}

.siswa-baru-list .tagihan.inactive {
  background-color: #ACB2B8;
}

.siswa-baru-list .registrasi .form-control {
  width: 50%;
  margin: auto;
  text-align: center;
  border: 1px solid #464646;
  border-radius: 0;
  font-size: 12px;
}

.siswa-baru-list .card-body {
  position: relative;
}

.siswa-baru-list .card-body canvas {
  padding: 2rem;
}

.siswa-baru-list .chart-total {
  position: absolute;
  right: 1rem;
  top: 3rem;
  font-size: 13px;
}

.siswa-baru-list .chart-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: -22px;
  margin-right: -22px;
  border-top: 1px solid #C4C4C4;
}

.siswa-baru-list .chart-desc .chart-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2rem;
  padding-bottom: 1rem;
  font-family: 'Roboto', sans-serif;
}

.siswa-baru-list .chart-desc .chart-data .circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-bottom: .5rem;
  border: 1px solid grey;
}

.siswa-baru-list .chart-desc .chart-data .data {
  font-weight: bold;
}

.login .content {
  width: 100%;
  height: 100vh;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login .content .item-wrapper {
  background-color: white;
  border-radius: .5rem;
  -webkit-box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.1);
  padding: 4rem 2rem;
  width: 34vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login .content .alert {
  width: 80%;
  padding: .5rem;
  margin-bottom: 2rem;
}

.login .content .alert .btn-close {
  padding: .75rem .5rem;
}

@media (max-width: 60em) {
  .login .content .item-wrapper {
    width: 100%;
  }
  .login .content .item-wrapper .alert {
    width: 100%;
  }
  .login .content .item-wrapper .logo {
    width: 100%;
  }
  .login .content .item-wrapper .logo .top {
    font-size: 1.25rem;
  }
  .login .content .item-wrapper .logo .middle {
    font-size: 1.75rem;
  }
  .login .content .item-wrapper form {
    width: 100%;
  }
  .login .content .item-wrapper form .form-label, .login .content .item-wrapper form .btn {
    font-size: 1rem;
  }
}

.login .content .logo {
  width: 64%;
  margin-top: 1rem;
  margin-bottom: 2.6rem;
}

.login .content .logo .top {
  font-size: 1.5rem;
}

.login .content .logo .middle {
  font-size: 1.8rem;
}

.login .content .logo .bottom img {
  margin-top: 0.4rem;
  width: 100%;
}

.login .content form {
  width: 80%;
}

.login .content form .form-label {
  font-size: 1.25rem;
}

.login .content .btn-login {
  width: 100%;
  text-align: center;
  background-color: #749A57;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
}

html, body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #464646;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

/* overlay loader */
.spanner{
    position:absolute;
    top: 50%;
    left: 0;
    background: #2a2a2a55;
    width: 100%;
    display:block;
    text-align:center;
    height: 300px;
    color: #FFF;
    transform: translateY(-50%);
    z-index: 1000;
    visibility: hidden;
  }
  
  .overlay{
    position: fixed;
      width: 100%;
      height: 100%;
    background: rgba(0,0,0,0.5);
    visibility: hidden;
  }
  
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #ffffff;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  
  .show{
    visibility: visible;
  }
  
  .spanner, .overlay{
      opacity: 0;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
  }
  
  .spanner.show, .overlay.show {
      opacity: 1
  }
/*# sourceMappingURL=style.css.map */

.pull-right {
  float: right;
  margin-top: 7px;
  margin-right: 7px;
}

/* custom modal */
.modal-content {
  width: 80%;
  margin: 0 auto;
}
.modal-body {
  padding: 0;
}
.my-btn-close {
  position: absolute;
  right: 0;
  padding: 1em;
}

.my-btn-save {
  width: 100%;  
  padding: 1em;
}

.modal-form {
  padding: 2em;
  max-width: 100%;
  /*color: #fff;*/
  box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);
  @media (max-width: 576px) {
    max-width: 90%;
    margin: 0 auto;
  }
}

/* Overlay Fullscreen */
.overlayfullscreen {
  /* Height & width depends on how you want to reveal the overlayfullscreen (see JS below) */   
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  /* background-color: #F5F5F5; */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlayfullscreen (height or width, depending on reveal) */
}

/* Position the content inside the overlayfullscreen */
.overlayfullscreen-content {
  position: relative;
  /* top: 25%; 25% from the top */
  top: 0;
  width: 100%; /* 100% width */
  /* text-align: center; Centered text/links */
  text-align: left;
  /* margin-top: 30px;  30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlayfullscreen */
.overlayfullscreen a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlayfullscreen a:hover, .overlayfullscreen a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlayfullscreen .closebtn {
  position: absolute;
  /* top: 20px;
  right: 45px;
  font-size: 60px; */
  top: 4px;
  right: 10px;
  font-size: 24px;
}

#myContent.showcontent {
  width: 100%;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlayfullscreen a {font-size: 20px}
  .overlayfullscreen .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}


input[type="text"]:disabled {
  background: #dddddd;
}

.show-pointer{
  cursor: pointer;
}

.inline {
  display: inline-block;
}

.w-sm {
  width: 150px;
}

/* custom search icon inline */
.siswa-baru-list .list-options .search-ops {
  position: relative;
  /* width: 30%; */
  margin-right: 1rem;
}
.siswa-baru-list .list-options .search-ops .icon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

/* Customizing the responsive behavior */
@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

/* Customizing the horizontal scroll styling */
.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-responsive::-webkit-scrollbar-corner {
  background: transparent;
}